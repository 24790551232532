<template>
  <div id="account-email">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>
      <div class="page-with-menu">
          <div class="row">
              <div class="col-md-4 col-lg-3">
                  <SidebarAccount active="changeEmail" />
              </div>
              <div class="col-md-8 ml-sm-auto col-lg-9 px-md-4">
                  <div class="card">
                      <div class="card-body">
                          <FormUserChangeEmail/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import SidebarAccount from './../../components/Sidebar.Account';
import FormUserChangeEmail from './../../components/forms/Form.User.ChangeEmail';

export default {
    components: {
        PageMenu,
        SidebarAccount,
        FormUserChangeEmail
    },
    data(){
        return {
            page: {
                title: "My Account",
            },
            breadcrumbs: [
                {
                    title: "Dashboard",
                    url: this.$router.resolve({name: "app.dashboard"}).href
                },
                {
                    title: "My Account",
                    url: this.$router.resolve({name: "app.user.account"}).href
                },
                {
                    title: "Change Email Address",
                }
            ]
        };
    },
    mounted(){

    },
};
</script>
