<template>
    <div class="page-sidebar-container">
        <ul class="page-sidebar">
            <li v-bind:class="setActiveSidebar('account')">
                <b-link :to="{name: 'app.user.account'}">
                    Profile Settings
                </b-link>
            </li>
            <!--
            <li v-bind:class="setActiveSidebar('2fa')">
                <b-link :to="{name: 'app.user.account.2fa'}">
                    Two-Factor Authentication
                </b-link>
            </li>
            -->
            <li v-bind:class="setActiveSidebar('changePassword')">
                <b-link :to="{name: 'app.user.account.changePassword'}">
                    Change Password
                </b-link>
            </li>
            <li v-bind:class="setActiveSidebar('changeEmail')">
                <b-link :to="{name: 'app.user.account.changeEmail'}">
                    Change E-Mail Address
                </b-link>
            </li>
            <!--
            <li v-bind:class="setActiveSidebar('camera')">
                <b-link :to="{name: 'app.user.account.camera'}">
                    Camera Settings
                </b-link>
            </li>


            <li v-bind:class="setActiveSidebar('billingAndPayments')">
                <a href="#">
                    Billing &amp; Payments
                </a>
            </li>
            -->
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Sidebar.Account',
    props: ['active'],
    components: {

    },
    methods: {
        setActiveSidebar(name){
            return this.active === name ? 'active' : '';
        },
    },
};
</script>
