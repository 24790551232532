<template>
    <FormTemplate v-if="form" :form="form">
        <b-form-group class="label-for-xl" label="Current E-Mail Address" label-for="inputCurrentEmail">
            <strong>{{ user.get('email') }}</strong>
        </b-form-group>
        <hr>
        <b-form-group class="label-for-xl" label="New E-Mail Address" label-for="inputNewEmail">
            <b-form-input
                type="text" id="inputNewEmail" class="form-control form-control-xl"
                placeholder="enter your new email address"
                v-model="form.data.email"
                :state="form.states.email"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.email }}</b-form-invalid-feedback>
        </b-form-group>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';


export default {
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },
    mounted(){
        this.form = new Form({email: ""},{
            onSubmit: (form) => {
              return this.$api.post('me/request/email-change',{
                  data: {
                      email: form.get('email'),
                  }
              })
            }
        });
    },

    computed: {
        user(){
            return this.$store.getters['User/Profile/getProfile'];
        }
    }
};
</script>
